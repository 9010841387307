// custom fontsource
import "@fontsource/roboto/300.css"

import React from "react";
import StoreWrapper from "./src/store/storeWrapper.js"

/**
*	Wraps the root element with the store Provider.
*	This allows the entire application to see the state managed by Redux.
*/
//export { default as wrapRootElement } from './src/store/storeWrapper';
export const wrapRootElement = ({ element }) => {
	if( typeof window !== "undefined" ) {
		window.onbeforeunload = function () {
			window.scrollTo(0,0);
		}
	}

	return (
		<StoreWrapper>
			{element}
		</StoreWrapper>
	)
}
