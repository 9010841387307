import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { default as homeReducer } from './home/homeSlice.js';

const reducer = {
	home: homeReducer
}

const createStore = (preloadedState) => configureStore({reducer, preloadedState});

const StoreWrapper = ({children, initialState}) => {
	return <Provider store={createStore(initialState)}> {children} </Provider>;
}

export default StoreWrapper;
