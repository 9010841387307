import { createSlice } from '@reduxjs/toolkit';


const homeSlice = createSlice({
	name: 'home',
	initialState: {
		viewChange: false,
		windowScrollY: 0,
	},

	reducers: {
		setView (state, action) {
			state.viewChange = action.payload;
		},

		setWindowScrollY ( state, action ) {
			state.windowScrollY = action.payload;
		}
	}
});


export const { setView, setWindowScrollY } = homeSlice.actions;
export const selectView = (state) => state.home.viewChange;
export const selectWindowScrollY = (state) => state.home.windowScrollY;

export default homeSlice.reducer;
